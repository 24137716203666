import { BoxProps, Text } from '@chakra-ui/react'
import React from 'react'

export const ListItemText = React.forwardRef<HTMLParagraphElement, BoxProps>(
  function ListItemText(props, ref) {
    return (
      <Text {...props} ref={ref} flex="1 1 auto" minW={0}>
        {props.children}
      </Text>
    )
  },
)
