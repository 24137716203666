import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

export type ListItemElementProps = {
  placement: 'left' | 'right'
} & BoxProps

export const ListItemElement = React.forwardRef<
  HTMLDivElement,
  ListItemElementProps
>(function ListItemElement(props, ref) {
  const { placement, ...restProps } = props
  const margin = 4
  const placementProps = {
    ml: placement === 'right' ? margin : 0,
    mr: placement === 'left' ? margin : 0,
  }
  return (
    <Box
      ref={ref}
      display="inline-flex"
      flexShrink={0}
      {...restProps}
      {...placementProps}
    >
      {props.children}
    </Box>
  )
})

export const ListItemElementLeft = React.forwardRef<HTMLDivElement, BoxProps>(
  function ListItemElementLeft(props, ref) {
    return <ListItemElement ref={ref} placement="left" {...props} />
  },
)

export const ListItemElementRight = React.forwardRef<HTMLDivElement, BoxProps>(
  function ListItemElementRight(props, ref) {
    return <ListItemElement ref={ref} placement="right" {...props} />
  },
)
